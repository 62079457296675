import React from "react";
import footerimage from "../Assets/AAF-Donation.jpeg";

const Footer = () => {
  
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   description: ""
  // });

  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [id]: value,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   alert("Form submitted with data:", formData);
  // };

  return (
    <div>
      <footer
        className="footer font-small pt-4"
        style={{ backgroundColor: "#ff8b7c12" }}
      >
        <div className="container text-center">
          <div className="row text-center">
            <div className="container col-lg-4 col-md-4 col-sm-12">
              <div
                className="fb-page"
                style={{ margin: "10px" }}
                data-tabs="timeline"
                data-href="https://www.facebook.com/DAUGHTERSFOREVER"
                data-width="380"
                data-height="600"
              ></div>
            </div>
            <div
              className="container  col-lg-3 col-md-3 col-sm-12"
              style={{ margin: "10px" }}
            >
              <img className="img-fluid" src={footerimage} alt="" />
            </div>

            {/* <div className=" container col-lg-4 col-md-4 col-sm-12">
              <div className="contact_container">
                <div className="form_container">
                  <div className="heading_contact">
                    <h2>Contact us</h2>
                  </div>

                  <form className="contact_form" onSubmit={handleSubmit}>
                    <div className="form-floating mb-3" style={{width:"300px"}}>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingName"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <label for="floatingName">Name</label>
                    </div>
                    <div className="form-floating mb-3" style={{width:"300px"}}>
                      <input
                        type="email"
                        className="form-control"
                        id="floatingEmail"
                        placeholder="name@example.com"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <label for="floatingEmail">Email address</label>
                    </div>
                    <div className="form-floating mb-3" style={{width:"300px"}}>
                      <input
                        type="tel"
                        className="form-control"
                        id="floatingPhone"
                        placeholder="phone no."
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      <label for="floatingPhone">Phone no.</label>
                    </div>
                    <div className="form-floating mb-3" style={{width:"300px"}}>
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        style={{height: "100px"}}
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                      <label for="floatingTextarea">Description</label>
                    </div>
                    <div className="button_submit">
                      <button type="submit" className="submit_button">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}

          </div>

          <hr />

          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8">
              <p className="text-center text-md-left">
                © All rights reserved with
                <a href="AKANKSHA ARADHYA FOUNDATION">
                  <strong>AKANKSHA ARADHYA FOUNDATION</strong>
                </a>
              </p>
            </div>

            <div className="col-md-5 col-lg-4 ml-lg-0">
              <div className="text-center text-md-right">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                      target="_blank"
                      href="https://www.facebook.com/DAUGHTERSFOREVER"
                    >
                      <i
                        className="fab fa-facebook-f"
                        style={{
                          backgroundColor: "#FF8B7C",
                          borderRadius: "100px",
                        }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
